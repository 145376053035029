var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                !_vm.hasComingClass && _vm.currentPage === 0
                  ? _c("div", { staticClass: "course-content-book" }, [
                      _c("div", { staticClass: "content-book" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/01-Menu/Book.svg"),
                            alt: "",
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "text-one font-menu-small" },
                          [_vm._v("You don't have any scheduled classes yet.")]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-two font-menu-small" },
                          [_vm._v("Book your next class!")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "text-button",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.bookButton.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Book a Class")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.hasComingClass
                  ? _c(
                      "div",
                      { staticClass: "course-content" },
                      [
                        _vm._l(
                          _vm.currentClassListInfo,
                          function (item, index) {
                            return [
                              _c("ClassBox", {
                                key: index,
                                attrs: {
                                  courseClassify: item.courseStatus,
                                  classListInfo: item,
                                },
                              }),
                            ]
                          }
                        ),
                        _vm.currentPage !== 0 && _vm.currentClassListInfo.length
                          ? _c(
                              "div",
                              { staticClass: "pagination" },
                              [
                                _c("el-pagination", {
                                  attrs: {
                                    "current-page": _vm.currentPageIndex,
                                    "page-sizes": [3, 5, 10],
                                    "page-size": _vm.pageSize,
                                    layout: "prev, pager, next",
                                    total: _vm.totalPage,
                                    background: "",
                                  },
                                  on: {
                                    "size-change": _vm.handleSizeChange,
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }